table {
  border-collapse: collapse;
  width: 100%;

  td, th {
    vertical-align: top;
    border: 1px solid $color__grey;
    padding: 0.25rem;
    text-align: left;
  }

  th {
    width: 30%;
  }
}
