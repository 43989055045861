section {
  &.testimonials {
    margin: 4em 0;
    background-color: $color__white;

    .pure-g {
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
    }

    .testimonial {
      .card {
        margin: 1em;
        padding: 1em;
        box-shadow: 0 0 2em rgba(0, 0, 0, 0.1);
      }

      .card__footer {
        font-weight: 700;
      }
    }
  }
}
