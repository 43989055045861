
section {
  &.services {
    background-color: $color__light-grey;
    margin: 4em 0;
    padding: 4em 0;

    .service {

      .card {
        padding: 1em;
        margin: 1em;
        background-color: $color__orange;
        color: $color__white;

        a {
          color: $color__white;
        }

        svg {
          fill: $color__white;
          height: 3em;
          width: auto;
        }

        h3 {
          margin: .5em 0;
        }
      }
    }
  }
}
