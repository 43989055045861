.modal {

    &-link {
        font-size: 80%;
    }

    display: none;

    &-background {
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9998;
    }

    &-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // bottom: 0;
        margin: auto;
        margin-top: 15%;
        border-radius: 1rem;
        border: 1px solid rgba(0,0,0,0.5);
        min-width: 300px;
        max-width: 600px;
        min-height: 300px;
        max-height: 80vh;

        background-color: #fff;
        box-sizing: border-box;
        z-index: 9999;

        display: flex;
        flex-direction: column;

        filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, .5));

        .modal-header {
            padding: 1rem;
            display: flex;
            align-items: flex-start;

            border-bottom: 1px solid rgba(0,0,0,0.5);
        }

        .modal-content {
            display: flex;
            padding: 1rem 2rem;
            flex: 1 1 auto;
            overflow-y: auto;
        }

        .modal-footer {
            display: flex;
            align-items: flex-end;
            padding: 1rem;
            border-top: 1px solid rgba(0,0,0,0.5);
        }
    }
}
