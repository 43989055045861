section {
	&.contact {
		background-color: $color__red;
		color: $color__white;

		a {
			color: $color__white;
		}


		.card {
			padding: 1em;
		}

		form {
			margin-top: 2.5em;
			label {
				font-weight: 700;
			}

			.pure-controls {
				text-align: right;
			}

			input {
				border-radius: .3em;
			}
		}

		.pure-g > div {
			margin-bottom: 1em;;
		}
	}
}