section {
  &.hero {
    background-color: $color__light-grey;
    padding: 2em 0;

    h1 {
      &.alternative {
        font-family: Poppins, sans-serif;
        font-weight: 400;
        font-size: 1.5em;
        margin: 0;
      }
    }

    p.tagline {
      font-size: 3em;
      margin: 0 0 1em 0;
      line-height: 1;
    }

    .btn--primary {
      background-color: $color__red;
      text-transform: uppercase;
      font-family: 'Barlow', sans-serif;
      font-weight: 800;

    }

    ul {
      list-style: none;
      margin: 0 0 2em 0;
      padding: 0;

      li {
        margin: 1em 0;
      }
    }

    .hero {
      &--image {
        overflow-x: hidden;
        overflow-y: visible;
        margin-bottom: -150%;
        padding-right: 2em;
        text-align: right;

        img {
          // margin-bottom: -10em;
          border-radius: 50%;
          // max-width: 100%;
        }
      }

      &--content {
        // background-color: rgba(255, 255, 255, 0.2);
        padding: 1em;
      }
    }
  }
}


@media screen and (min-width: 48em) { // 768 / md
  section {
    &.hero {
      .hero {
        &--image {
          margin-bottom: -100%;
          width: 100%;

        }

        &--content {
          padding: 0;

        }
      }
    }
  }
}
