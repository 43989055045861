section {
  &.header {
    background-color: $color__white;
    padding: 1em 0;

    a.logo {
      svg {
        fill: $color__red !important;
        max-height: 4em;
      }

    }

    .main-menu {
      text-align: right;
      margin: 1.5em 0;
    }
  }
}

@media screen and (min-width: 48em) { // 768 / md
  section {
    &.header {
      padding: 0;

      .logo {
        padding: .5em 0;
      }
    }
  }
}
