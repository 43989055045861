
section {
  &.footer {
    // background-color: $color__red;
    color: $color__white;
    line-height: $font__lineheight;

    .rounded {
      padding: 2em;
      background-color: $color__black;
      border-top-right-radius: 8em;
    }


    a {
      color: $color__white;
    }



    svg {
      fill: $color__white;
      max-width: 15em;
      height: auto;
    }

    .vcard {
      margin-bottom: 1em;
    }
  }
}


body {
  &.home {
    section {
      &.footer {
        background-color: $color__red;
      }
    }
  }
}
