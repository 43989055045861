
section {
  &.cases {
    background-color: $color__white;
    margin: 4em 0;

    .case {

      color: $color__white;
      a {
        color: $color__white;
      }

      &__background {
        margin: 2em 0;
        padding: 3em;

        &:nth-child(even) {
          background-color: $color__red;
        }

        &:nth-child(odd) {
          background-color: $color__orange;
        }

      }

      &--image {
        text-align: center;
        img {
          max-width: 50vw;
        }
      }
    }
  }
}


@media screen and (min-width: 48em) { // 768 / md
  section {
    &.cases {

      .case {
        &__background {
          padding: 0;

          .container {
            padding: 3em;
          }

          &:nth-child(even) {
            background: linear-gradient(90deg, $color__red 60%, rgba(255,255,255,0) 40%);
            margin-right: 10em;

            .pure-g {
              flex-direction: row-reverse;
            }

            .container {
              border-top-right-radius: 10em;
              border-bottom-right-radius: 10em;
              background-color: $color__red;
            }

            .case--image {
              img {
                right: -10em;
              }
            }
          }

          &:nth-child(odd) {

            background: linear-gradient(270deg, $color__orange 60%, rgba(255,255,255,0) 40%);
            margin-left: 10em;

            .container {
              background-color: $color__orange;
              border-top-left-radius: 10em;
              border-bottom-left-radius: 10em;
            }

            .case--image {
              img {
                left: -10em;
              }
            }
          }
        }

        &--image {
          position: relative;

          img {
            position: absolute;
            max-height: 15em;
            top: 2em;
            min-width: 300px;
            width: 100%;
            max-width: 400px;
          }
        }

        // &--description {
        //  > * {
        //    padding: 0 1em;
        //  }
        // }
      }
    }
  }
}
