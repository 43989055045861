
section {
  &.about {
    padding-top: 4rem;
    background-color: $color__white;

    .pure-g {
      flex-direction: column-reverse;
    }

    img {
      border-radius: 50%;
      width: 65%;
      // display: none;
    }

    .about {

      &--image {
        // display: none;
        text-align: center;
        padding-top: 2rem;

        svg {
          width: 250px;
          fill: $color__red;
        }

        .annotation {
          font-size: 0.8rem;
        }
      }


    }

    a > span.arrow {
      font-size: 1.3em;
    }
  }
}

// @media screen and (max-width: 35.5em) { // <568 / xs
//   section {
//     &.about {
//       padding-top: 10em;
//     }
//   }
// }

@media screen and (min-width: 48em) { // 768 / md
  section {
    &.about {
      padding-top: 7rem;

      .pure-g {
        flex-direction: row;
      }

      .about {
        &--image {
          display: block;
          margin-top: 5em;
          padding-top: 0;
        }
      }
    }
  }
}
