
@import '~purecss-sass/vendor/assets/stylesheets/purecss/base';
@import '~purecss-sass/vendor/assets/stylesheets/purecss/buttons';
@import '~purecss-sass/vendor/assets/stylesheets/purecss/forms';
@import '~purecss-sass/vendor/assets/stylesheets/purecss/grids';
@import '~purecss-sass/vendor/assets/stylesheets/purecss/grids-responsive';
@import '~purecss-sass/vendor/assets/stylesheets/purecss/menus';
@import '~purecss-sass/vendor/assets/stylesheets/purecss/tables';

// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/maps";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/helpers";
// @import "~bootstrap/scss/utilities/api";

// colors
$color__white: rgba(255, 255, 255, 1);
$color__white--transparent: rgba(255, 255, 255, 0.7);
$color__red: rgba(196, 75, 23, 1);
$color__orange: rgba(227, 133, 6, 1);
$color__light-grey: rgba(248, 243, 237, 1);
$color__grey: rgba(0, 0, 0, 0.1);
$color__black: rgba(45, 49, 52, 1);
$color__green: rgba(96, 114, 102, 1);

$color__font: $color__black;
$color__link: $color__font;
$color__primary: $color__orange;

$font__lineheight: 1.7;



@import "components/buttons";
@import "components/forms";
@import "components/navigation";
@import "components/typography";
@import "components/tables";
@import "components/alerts";
@import "components/modal";

@import 'sections/header';
@import 'sections/hero';
@import 'sections/about';
@import 'sections/services';
@import 'sections/cases';
@import 'sections/testimonials';
@import 'sections/contact';
@import 'sections/footer';
@import 'sections/knowledgebase';

@import "utilities";

html {
  scroll-behavior: smooth;
}

body {
  min-width: 20rem;
}

// @media screen and (max-width: 35.5em) { // <568 / xs
// }

// @media screen and (min-width: 35.5em) { // 568 / sm
// }

// @media screen and (min-width: 48em) { // 768 / md
// }

// @media screen and (min-width: 64em) { // 1024 / lg
// }

// @media screen and (min-width: 80em) { // 1280 / xl
// }

// @media screen and (min-width: 120em) { // 1920 / xxl
// }



