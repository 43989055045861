section {
  &.knowledgebase {

    img {
      max-width: 100%;
      margin: 1rem auto;
      border: 1px solid $color__grey;

      @media screen and (min-width: 48em) { // 768 / md
        max-width: 70%;
      }
    }

    table {
      margin-bottom: 1rem;
    }
  }
}
