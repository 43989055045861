
.custom-menu-toggle {
  width: 44px;
  height: 44px;
  // display: block;
  position: fixed;
  top: 3px;
  right: 3px;
  // display: none;
  background-color: $color__white--transparent;

  .bar {
    background-color: $color__black;
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 100px;
    position: absolute;
    top: 22px;
    right: 12px;
    transition: all 0.3s;

    &:first-child {
      transform: translateY(-6px);
    }

    &:last-child {
      transform: translateY(6px);
    }
  }

  &.x {
    background-color: $color__red;
    .bar {
      background-color: $color__white;
      transform: rotate(45deg);

      &:first-child {
        transform: rotate(-45deg);
      }

      &:last-child {
        visibility: none;
      }
    }

  }
}

.mobile-menu {
  width: 15em;
  margin-left: -20em;
  background-color: $color__red;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  box-shadow: .5em 0 1em rgba(0,0,0,0.4);
  transition: all 0.5s;

  a {
    color: $color__white;

    &:hover {
      background-color: $color__orange;
    }
  }

  &.active {
    margin-left: 0;
  }
}

