
.alert-container {
  position: relative;
}

.alert {
  position: absolute;
  width: 65%;
  max-width: 40rem;
  left: 15%;
  // margin-left: -25%;
  top: -1rem;

  background-color: #fff;
  padding: 0 2rem;
  border-radius: 0.5rem;
  border: 1px solid $color__orange;
  box-shadow: 0 2px 5px -0px rgba(0, 0, 0, 0.4);
}
