body {
    color: $color__font;
    background-color: $color__white;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    overflow-x: hidden;
}

p {
    line-height: $font__lineheight;
}

strong, b {
    font-weight: 700;
}


a {
    color: $color__link;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}


h1, h2, h3, h4, h5, h6, .tagline {
    font-family: 'Barlow', sans-serif;
    font-weight: 800;
    text-transform: uppercase;

    > span.super {
        vertical-align: super;
        font-size: 60%;
    }
}

section {
    h2 {
        font-size: 3em;
        line-height: 0.75;
    }
}

.pure-g [class *= "pure-u"] {
    /* Set your content font stack here: */
    font-family: 'Poppins', sans-serif;

    & * {
        letter-spacing: 0;
    }
}


.vcard {
    line-height: $font__lineheight;
}

span.nb {
  font-size: 0.8rem;
}

section {
  &.privacy, &.knowledgebase {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
      line-height: unset;
    }

    p {
      margin-bottom: 1rem;
    }

    li {
      line-height: $font__lineheight;
      margin-bottom: 0.25rem;
    }

    a {
      color: $color__red;
    }
  }
}
