.btn {
  &--xsmall {
        font-size: 70%;
    }

    &--small {
        font-size: 85%;
    }

    &--large {
        font-size: 110%;
    }

    &--xlarge {
        font-size: 125%;
    }

    &--primary {
      background-color: $color__orange;
      color: $color__white;

        &:hover {
            background-color: $color__orange;
        }
    }
}
