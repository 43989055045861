
.u-hide-xs,
.u-hide-sm,
.u-hide-md,
.u-hide-lg,
.u-hide-xl,
.u-hide-xxl {
  display: none;
}

// .container {
//   max-width: 1140px;
//   margin: 0 auto;
//   padding: .5em;
// }

// .text-center {
//   text-align: center;
// }

.b {
  &-container {
    max-width: 1140px;
    margin: 0 auto;
    padding: .5em;
  }
}



@media screen and (min-width: 35.5em) { // 568 / sm
  .u-hide-sm {
    display: unset;
  }

  .u-hide-md,
  .u-hide-lg,
  .u-hide-xl,
  .u-hide-xxl {
    display: none;
  }

  .u-show-sm {
    display: none;
  }
}


@media screen and (min-width: 48em) { // 768 / md
  .u-hide-md {
    display: unset;
  }

  .u-hide-lg,
  .u-hide-xl,
  .u-hide-xxl {
    display: none;
  }

  .u-show-md {
    display: none;
  }
}


@media screen and (min-width: 64em) { // 1024 / lg
  .u-hide-lg {
    display: unset;
  }

  .u-hide-xl,
  .u-hide-xxl {
    display: none;
  }

  .u-show-lg {
    display: none;
  }
}


@media screen and (min-width: 80em) { // 1280 / xl
  .u-hide-xl {
    display: unset;
  }

  .u-hide-xxl {
    display: none;
  }

  .u-show-xl {
    display: none;
  }
}


@media screen and (min-width: 120em) { // 1920 / xxl
  .u-hide-xxl {
    display: unset;
  }

  .u-show-xxl {
    display: none;
  }
}
